
// [Major, minor, patch]

class Version {

    // versionCode: [Major, minor, patch]
    constructor(versionCode){
        if(!Array.isArray(versionCode)){
            versionCode = versionCode?.versionCode ?? [];
        }

        this.versionCode = versionCode;
    }

    equals(version) {
        for(let i = 0; i < this.versionCode.length; i++){
            if(version.versionCode[i] !== this.versionCode[i]){
                return false;
            }
        }
        return this.versionCode.length === version.versionCode.length;
    }

    isHigherThan(version, depth = 2){
        // Null coalescing operator
        let maxDepth = this.versionCode?.length ?? 0;
        if(depth > maxDepth){
            depth = maxDepth;
        }

        if(version.versionCode?.length ?? 0 < depth){
            return false;
        }

        for(let i = 0; i < depth; i++){
            if(this.versionCode[i] > version.versionCode[i]){
                return true;
            }
            if(this.versionCode[i] < version.versionCode[i]){
                return false;
            }
        }
        return false;
    }

    isLowerThan(version, depth = 2){
        return !this.equals(version) && !this.isHigherThan(version, depth);
    }
}

let VERSION = new Version([1, 0, 0]);

export {VERSION, Version};
