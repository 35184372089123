import React, {Component} from "react";
import {Version} from "./globals";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

const compareArrays = (array1, array2) => (
    array1.length === array2.length && array1.every((value, index) => value === array2[index])
);

class UpgradeDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            closed: false
        }
    }

    handleUpdate = () => {
        this.handleClose();
        this.props.onDefaultSchifChange(this.upgradePath())
    };

    handleClose = () => {
        this.setState({
            closed: true
        })
    };

    isUpgradePossible= () => {
        return compareArrays(this.props.currentShift?.scheme?.names, ["R", "R", "O", "O", "N", "N", "-", "-"])
            && this.upgradePath() !== null;
    };

    upgradePath = () => {

        for(let shiftCandidate of this.props.shifts){
            let interchangeable = shiftCandidate.days === this.props.currentShift.days
                && shiftCandidate.offset === this.props.currentShift.offset
                && compareArrays(shiftCandidate.scheme?.names, ["R", "R", "N", "N", "O", "O", "-", "-"]);

            if(interchangeable){
                return shiftCandidate;
            }
        }
        return null;
    };

    render() {

        if(!this.props.updgradedFrom?.isLowerThan(new Version([1, 0, 0]))){
            return null;
        }

        //alert("Upgraded from " + this.props.updgradedFrom.versionCode);

        let upgradePossible = this.isUpgradePossible();

        return (
            <Dialog
                open={upgradePossible && !this.state.closed}
            >
                <DialogTitle>Změnit na nové šichty?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Od nového roku se vetšině železáren mění šichty. Chceš, abychom tě na ně taky přepli?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary">
                        Ne, zobrazovat šichty postaru
                    </Button>
                    <Button onClick={this.handleUpdate} color="primary" autoFocus>
                        Jasně!
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default UpgradeDialog;
